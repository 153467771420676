import { useEffect } from "react";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const PreviewPostData = () => {
  const previewData = JSON.parse(localStorage.getItem("postPreview"));

  useEffect(() => {
    const postDetailsElement = document.getElementById("postDetails");

    postDetailsElement.innerHTML = `
                  <div class="col-12">
                  <div class="title">${previewData.titleEn}</div>
                </div>
                <div class="col-12"  style="display: flex; justify-content: center;">
                  <img src="${previewData.attachmentUrl}" alt="" class="" />
                </div>
                <div class="col-12">
                    ${previewData.descriptionEn}
                </div>
              `;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="post-details">
      <div className="container">
        <div id="postDetails"></div>
      </div>
    </section>
  );
};

export default PreviewPostData;
