import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
import { Posts } from "./postsSlice";
const attachmentApi = factory.get("attachment");
const initialState = {
  pageNo: 1,
  searchBody: {},
  attachment: {
    loading: false,
    dialog: false,
    attachment: {
      file: null,
    },
  },
  attachments: {
    loading: false,
    data: [],
    total: 0,
  },
  changeBackgroundImage: {
    loading: false,
    dialog: false,
    form: {
      file: null,
      imageUrl: "",
    },
    image: [],
  },
};

export const attachmentSlice = createSlice({
  name: "attachment",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.attachment.dialog = !state.attachment.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.attachments.data = payload.data.data;
      state.attachments.total = payload.data.total;
    },
    setById: (state, { payload }) => {
      state.attachment.attachment = payload;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.attachment.attachment = initialState.attachment.attachment;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    setPageNo: (state, { payload }) => {
      state.pageNo = payload;
    },
    setChangeBackgroundDialog: (state, { payload }) => {
      state.changeBackgroundImage.dialog = !state.changeBackgroundImage.dialog;
      if (payload) {
        state.changeBackgroundImage.form.imageUrl = payload;
      }
    },
    setChangeBackgroundData: (state, { payload }) => {
      state.changeBackgroundImage.image = [payload.data.data];
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setPageNo,
  setChangeBackgroundData,
  setChangeBackgroundDialog,
} = attachmentSlice.actions;

export default attachmentSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("attachment"));
    const res = await attachmentApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("attachment"));
  } catch (err) {
    dispatch(setLoading("attachment"));
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await attachmentApi.delete(id);
    dispatch(
      showNotification({
        message: "Form Deleted Successfully",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("attachment"));
    const res = await attachmentApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("attachment"));
  } catch (err) {
    dispatch(setLoading("attachment"));
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, formData, pageNo, searchBody) => async (dispatch) => {
  try {
    const res = await attachmentApi.create(data);
    dispatch(
      showNotification({
        message: "Form Created Successfully",
        type: "success",
      })
    );
    dispatch(
      Posts.create(
        {
          title: formData.title,
          description: formData.description,
          attachmentId: res.data.data.id,
        },
        pageNo,
        searchBody
      )
    );
    // dispatch(setDialog());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await attachmentApi.update(id, data);
    dispatch(
      showNotification({
        message: "Form Updated Successfully",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const changeBackgroundImage = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("changeBackgroundImage"));
    await attachmentApi.changeBackgroundImage(data);
    dispatch(
      showNotification({
        message: "Updated Successfully",
        type: "success",
      })
    );
    dispatch(setLoading("changeBackgroundImage"));
    dispatch(setChangeBackgroundDialog());
    dispatch(getBackgroundImage());
  } catch (err) {
    dispatch(setLoading("changeBackgroundImage"));
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const getBackgroundImage = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("changeBackgroundImage"));
    const res = await attachmentApi.getBackgroundImage(params);
    dispatch(setChangeBackgroundData(res));
    dispatch(setLoading("changeBackgroundImage"));
  } catch (err) {
    dispatch(setLoading("changeBackgroundImage"));
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Attachments = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  changeBackgroundImage,
  getBackgroundImage,
};
