import {
  Box,
  Button,
  Card,
  ClickAwayListener,
  Grow,
  MenuList,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState, useEffect, useRef } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
} from "date-fns";

export default function DateRangepicker({
  handleOnChange = function () {},
  startDate,
  endDate,
  disabled,
}) {
  const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  };
  const staticRangeHandler = {
    range: {},
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  };
  function createStaticRanges(ranges) {
    return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
  }

  const defaultStaticRanges = createStaticRanges([
    {
      label: "Today",
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },

    {
      label: "This Week",
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek,
      }),
    },
    {
      label: "Last Week",
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek,
      }),
    },
    {
      label: "This Month",
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
      }),
    },
    {
      label: "Last Month",
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
      }),
    },
  ]);

  const defaultInputRanges = [
    {
      label: "days up to today",
      range(value) {
        return {
          startDate: addDays(
            defineds.startOfToday,
            (Math.max(Number(value), 1) - 1) * -1
          ),
          endDate: defineds.endOfToday,
        };
      },
      getCurrentValue(range) {
        if (!isSameDay(range.endDate, defineds.endOfToday)) return "-";
        if (!range.startDate) return "∞";
        return (
          differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1
        );
      },
    },
    {
      label: "days starting today",
      range(value) {
        const today = new Date();
        return {
          startDate: today,
          endDate: addDays(today, Math.max(Number(value), 1) - 1),
        };
      },
      getCurrentValue(range) {
        if (!isSameDay(range.startDate, defineds.startOfToday)) return "-";
        if (!range.endDate) return "∞";
        return (
          differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1
        );
      },
    },
  ];

  const [searchDate, setSearchDate] = useState([
    {
      startDate: startDate ? dayjs(startDate).toDate() : null,
      endDate: endDate ? dayjs(endDate).toDate() : new Date(""),
      key: "selection",
    },
  ]);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  // useEffect(() => {
  //   if (open == false) {
  //     handleOnChange(searchDate)
  //   }
  // }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.stopPropagation();
    }
  }

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack>
      <Box>
        <Popper
          open={disabled ? false : open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{ zIndex: 9999 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "right bottom" : "right top",
              }}
            >
              <Card>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="end"
                      sx={{
                        ".rdrStaticRanges": {
                          alignItems: "center",
                        },
                        ".rdrInputRanges": {
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        },
                        ".rdrStaticRangeLabel": {
                          fontFamily: "Cairo",
                        },
                      }}
                    >
                      <DateRangePicker
                        onChange={(item) => {
                          setSearchDate([item.selection]);
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={true}
                        months={2}
                        ranges={searchDate}
                        direction="horizontal"
                        editableDateInputs={true}
                        dateDisplayFormat="yyyy-MM-dd"
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                      />
                      <Button
                        onClick={() => {
                          handleOnChange(searchDate);
                          setOpen(false);
                        }}
                        variant="contained"
                        sx={{ margin: 2, width: 200 }}
                      >
                        OK
                      </Button>
                    </Stack>
                  </MenuList>
                </ClickAwayListener>
              </Card>
            </Grow>
          )}
        </Popper>
      </Box>
      <Stack
        ref={anchorRef}
        direction="row"
        spacing={2}
        sx={{ alignItems: "center", cursor: "pointer" }}
        onClick={() => handleToggle()}
      >
        <TextField
          disabled={disabled}
          autoComplete="off"
          fullWidth
          size="small"
          value={`${startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""}`}
          name="dateRange"
          label="From Date"
        />
        <Typography>To</Typography>
        <TextField
          disabled={disabled}
          autoComplete="off"
          fullWidth
          size="small"
          value={`${endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""}`}
          name="dateRange"
          label="To date"
        />
      </Stack>
    </Stack>
  );
}
