import { Dialog, DialogContent, Grid, Slide } from "@mui/material";
import React, { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CourseCategoryTable from "./home/table";
import CourseCategoryHeader from "./home/header";
import { setOpenDialog } from "../../../app/slices/courseCategorySlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CourseCategory = () => {
  const _CourseCategory = useSelector((state) => state.courseCategory);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setOpenDialog());
  };

  return (
    <Dialog
      open={_CourseCategory.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      onClose={() => {
        handleDialog();
      }}
      maxWidth="md"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CourseCategoryHeader />
          </Grid>
          <Grid item xs={12}>
            <CourseCategoryTable />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CourseCategory;
