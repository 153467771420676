import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const courseApi = factory.get("course");
const initialState = {
  searchBody: {
    courseCategoryId: null,
    name: "",
  },
  course: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      courseCategoryId: null,
      attachment: null,
      attachmentId: null,
      nameEn: "",
      nameAr: "",
      nameKu: "",
      descriptionEn: "",
      descriptionAr: "",
      descriptionKu: "",
    },
  },
  courses: {
    loading: false,
    data: [],
    total: 0,
  },
};

export const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.course.dialog = !state.course.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.courses.data = payload.data.data;
      state.courses.total = payload.data.total;
      state.courses.total_amount = payload.data.total_amount;
    },
    setById: (state, { payload }) => {
      state.course.form.id = payload.id;
      state.course.form.descriptionAr = payload.descriptionAr;
      state.course.form.descriptionEn = payload.descriptionEn;
      state.course.form.descriptionKu = payload.descriptionKu;
      state.course.form.nameEn = payload.nameEn;
      state.course.form.nameAr = payload.nameAr;
      state.course.form.nameKu = payload.nameKu;
      state.course.form.attachmentId = payload.attachment?.id;
      state.course.form.attachment = payload.attachment?.url;
      state.course.form.courseCategoryId = payload.category?.id;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.course.form = initialState.course.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
} = courseSlice.actions;

export default courseSlice.reducer;

//axios
const getAllCourses = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("courses"));
    const res = await courseApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("courses"));
  } catch (err) {
    dispatch(setLoading("courses"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("course"));
    await courseApi.delete(id);
    dispatch(
      showNotification({
        message: "Deleted successfully",
        type: "success",
      })
    );
    dispatch(setLoading("course"));
    dispatch(getAllCourses(_SearchBody));
  } catch (err) {
    dispatch(setLoading("course"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("courses"));
    const res = await courseApi.getById(id);
    dispatch(setById(res.data.data));

    dispatch(setDialog());
    dispatch(setLoading("courses"));
  } catch (err) {
    dispatch(setLoading("courses"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, searchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("course"));
    await courseApi.create(data);
    dispatch(
      showNotification({
        message: "Added successfully",
        type: "success",
      })
    );
    dispatch(setLoading("course"));
    dispatch(setDialog());
    dispatch(getAllCourses(searchBody));
  } catch (err) {
    dispatch(setLoading("course"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("course"));
    await courseApi.update(id, data);
    dispatch(
      showNotification({
        message: "Updated successfully",
        type: "success",
      })
    );
    dispatch(setLoading("course"));
    dispatch(setDialog());
    dispatch(getAllCourses(_SearchBody));
  } catch (err) {
    dispatch(setLoading("course"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const CourseActions = {
  getAllCourses,
  deleteById,
  getById,
  create,
  update,
};
