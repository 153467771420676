// project imports
import MainLayout from "../layout/MainLayout";
import Applicants from "../views/applicants";
import ChangeBackgroundImage from "../views/changeBackgroundImage";
import Course from "../views/course";
import Posts from "../views/posts";
import PreviewPostData from "../views/posts/preview";
import Users from "../views/users";

// dashboard routing

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/users",
      element: <Users />,
    },
    {
      path: "/applicants",
      element: <Applicants />,
    },
    {
      path: "/posts",
      element: <Posts />,
    },
    {
      path: "/preview_post",
      element: <PreviewPostData />,
    },
    {
      path: "/course",
      element: <Course />,
    },
    {
      path: "/website_background_image",
      element: <ChangeBackgroundImage />,
    },
  ],
};

export default MainRoutes;
