import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiEdit } from "react-icons/bi";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Attachments,
  setChangeBackgroundDialog,
} from "../../../../app/slices/attachmentSlice";

const ChangeBackgroundImageTable = () => {
  const dispatch = useDispatch();

  const _Attachment = useSelector(
    (state) => state.attachment.changeBackgroundImage
  );
  const loading = useSelector(
    (state) => state.attachment.changeBackgroundImage.loading
  );

  const columns = [
    {
      field: "attachment",
      headerName: "Image",
      flex: 2,
      renderCell: (params) => (
        <a href={params.row.url} target="_blank" rel="noopener noreferrer">
          <Box
            component="img"
            src={params.row.url}
            width="300px"
            height="300px"
            alt="attachment"
            sx={{ borderRadius: "10px", objectFit: "contain" }}
          />
        </a>
      ),
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="primary"
              onClick={() =>
                dispatch(setChangeBackgroundDialog(params.row.url))
              }
            >
              <BiEdit />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Attachments.getBackgroundImage());
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch]);

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={{}}>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={_Attachment.image}
                columns={columns}
                loading={loading}
                rowHeight={300}
                pagination
                paginationMode="client"
                selectionModel={[]}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ChangeBackgroundImageTable;
