import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import CourseCategoryForm from "../../from";
import { setTypesDialog } from "../../../../../app/slices/courseCategorySlice";
import { MdCategory } from "react-icons/md";

const CourseCategoryHeader = () => {
  const _CourseCategory = useSelector(
    (state) => state.courseCategory.courseCategory
  );
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            Department
          </Typography>
        }
        avatar={<MdCategory size={20} />}
        action={
          <Button
            sx={{ width: "200px" }}
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setTypesDialog())}
          >
            Add Department
          </Button>
        }
      />
      {_CourseCategory.dialog ? <CourseCategoryForm /> : null}
    </Card>
  );
};

export default CourseCategoryHeader;
