import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiTrash, BiEdit } from "react-icons/bi";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import { DataGrid } from "@mui/x-data-grid";
import { CourseCategoryActions } from "../../../../../app/slices/courseCategorySlice";

const CourseCategoryTable = () => {
  const dispatch = useDispatch();

  const _CourseCategory = useSelector(
    (state) => state.courseCategory.courseCategories
  );
  const loading = useSelector(
    (state) => state.courseCategory.courseCategories.loading
  );
  const _SearchBody = useSelector((state) => state.courseCategory.searchBody);

  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(CourseCategoryActions.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "nameEn",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "attachment",
      headerName: "Attachment",
      flex: 1,
      renderCell: (params) => (
        <a
          href={params.row.attachment?.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box
            component="img"
            src={params.row.attachment?.url}
            width="100px"
            height="100px"
            alt="attachment"
            sx={{ borderRadius: "10px", objectFit: "contain" }}
          />
        </a>
      ),
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() =>
                dispatch(CourseCategoryActions.getById(params.row.id))
              }
            >
              <BiEdit />
            </IconButton>
            <IconButton onClick={() => handleDeleteClick(params.row)}>
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(CourseCategoryActions.getAll());
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="Confirmation"
            message="Are you sure you want to delete"
            name={dialogData.name}
            confirmText="Delete"
            cancelText="Cancel"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12}>
            <DataGrid
              rows={_CourseCategory.data}
              columns={columns}
              rowCount={_CourseCategory.total}
              loading={loading}
              rowHeight={120}
              autoHeight
              paginationMode="client"
              selectionModel={[]}
              rowsPerPageOptions={[15, 25, 50, 100]}
              components={{
                LoadingOverlay: LinearProgress,
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CourseCategoryTable;
