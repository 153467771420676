import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Stack,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  CourseCategoryActions,
  resetForm,
  setTypesDialog,
} from "../../../../app/slices/courseCategorySlice";
import FilePondInput from "../../../../helpers/filepond";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CourseCategoryForm = () => {
  const _CourseCategory = useSelector(
    (state) => state.courseCategory.courseCategory
  );
  const _SearchBody = useSelector((state) => state.courseCategory.searchBody);
  const loading = useSelector(
    (state) => state.courseCategory.courseCategory.loading
  );

  const isAdd = _CourseCategory.form.id ? false : true;
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: _CourseCategory.form,
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("nameAr", data.nameAr);
    formData.append("nameEn", data.nameEn);
    formData.append("nameKu", data.nameKu);

    if (data.temp_attachments) {
      formData.append("attachment", data.temp_attachments);
    } else {
      formData.append("attachmentId", _CourseCategory.form.attachmentId);
    }

    if (!isAdd) {
      formData.append("id", data.id);
    }

    if (isAdd) {
      dispatch(CourseCategoryActions.create(formData));
    } else {
      dispatch(CourseCategoryActions.update(data.id, formData, _SearchBody));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setTypesDialog());
  };

  return (
    <Dialog
      open={_CourseCategory.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "Add " : "Edit "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="nameAr"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Name Arabic"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="nameEn"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Name English"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="nameKu"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Name Kurdish"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="temp_attachments"
                control={control}
                render={({ field }) => (
                  <Box>
                    {isAdd ? null : (
                      <Stack mb={2} justifyContent="center" alignItems="center">
                        <Box
                          component="img"
                          src={_CourseCategory.form.attachment}
                          sx={{
                            width: 300,
                            border: "solid 1px gray",
                            padding: 4,
                            borderRadius: 5,
                            objectFit: "contain",
                          }}
                          alt="post image"
                        />
                      </Stack>
                    )}
                    <FilePondInput
                      required={isAdd ? true : false}
                      field={field}
                      onChange={(fileItems) => {
                        setValue(`temp_attachments`, fileItems[0]?.file);
                      }}
                      onremovefile={(fileItems) => {
                        setValue(`temp_attachments`, null);
                      }}
                    />
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                sx={{ width: 200 }}
                type="submit"
                variant="contained"
                color="primary"
              >
                {loading ? <CircularProgress size={20} /> : "Save"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CourseCategoryForm;
