import { Card, CardHeader, Typography, Button, Stack } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import CourseForm from "../../from";
import CourseCategory from "../../courseCategory";
import { setOpenDialog } from "../../../../app/slices/courseCategorySlice";
import { setDialog } from "../../../../app/slices/courseSlice";
import { MdComputer } from "react-icons/md";

const CourseHeader = () => {
  const _Course = useSelector((state) => state.course);
  const _CourseCategory = useSelector((state) => state.courseCategory);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            Courses
          </Typography>
        }
        avatar={<MdComputer size={20} />}
        action={
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              sx={{ width: "200px" }}
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setOpenDialog())}
            >
              Department
            </Button>
            <Button
              sx={{ width: "200px" }}
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setDialog())}
            >
              Add Course
            </Button>
          </Stack>
        }
      />
      {_Course.course.dialog ? <CourseForm /> : null}
      {_CourseCategory.dialog ? <CourseCategory /> : null}
    </Card>
  );
};

export default CourseHeader;
