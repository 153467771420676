import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import ChangeBackgroundImageForm from "../../form";
import { IoMdImage } from "react-icons/io";

const ChangeBackgroundImageHeader = () => {
  const attachment = useSelector(
    (state) => state.attachment.changeBackgroundImage.dialog
  );
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            Background Image
          </Typography>
        }
        avatar={<IoMdImage size={20} />}
      />
      {attachment && <ChangeBackgroundImageForm />}
    </Card>
  );
};

export default ChangeBackgroundImageHeader;
