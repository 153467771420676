import { forwardRef, useEffect, useState } from "react";
import {
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Autocomplete,
  CircularProgress,
  Stack,
  Fab,
  Box,
  DialogActions,
  Button,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  Posts,
  setPreviewData,
  setById,
} from "../../../app/slices/postsSlice";
import { Controller, useForm } from "react-hook-form";
import FilePondInput from "../../../helpers/filepond";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { showNotification } from "../../../app/slices/notificationSlice";
import { AiFillEye, AiOutlineSave } from "react-icons/ai";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PostForm = () => {
  const _Post = useSelector((state) => state.post.post);
  const _SearchBody = useSelector((state) => state.post.searchBody);
  const _PageNo = useSelector((state) => state.post.pageNo);
  const loading = useSelector((state) => state.post.post.loading);

  // const [editorStateTitle, setEditorStateTitle] = useState(
  //   EditorState.createEmpty()
  // );
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // const [preview, setPreview] = useState(false);
  const [files, setFiles] = useState(null);

  const isAdd = _Post.form.id ? false : true;
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: _Post.form,
  });

  // useEffect(() => {
  //   const blocksFromHtml = htmlToDraft(watch("description"));
  //   const { contentBlocks, entityMap } = blocksFromHtml;
  //   const contentState = ContentState.createFromBlockArray(
  //     contentBlocks,
  //     entityMap
  //   );
  //   const newEditorState = EditorState.createWithContent(contentState);
  //   setEditorState(newEditorState);
  // }, [_Post.form.description, watch]);

  // useEffect(() => {
  //   const blocksFromHtml = htmlToDraft(watch("title"));
  //   const { contentBlocks, entityMap } = blocksFromHtml;
  //   const contentState = ContentState.createFromBlockArray(
  //     contentBlocks,
  //     entityMap
  //   );
  //   const newEditorState = EditorState.createWithContent(contentState);
  //   setEditorStateTitle(newEditorState);
  // }, [_Post.form.title, watch]);

  // const onEditorStateChange = (editorState) => {
  //   setEditorState(editorState);
  //   setValue(
  //     "description",
  //     `${draftToHtml(convertToRaw(editorState.getCurrentContent()))}`
  //   );
  // };

  // const onEditorStateChangeTitle = (editorState) => {
  //   setEditorStateTitle(editorState);
  //   setValue(
  //     "title",
  //     draftToHtml(convertToRaw(editorState.getCurrentContent()))
  //   );
  // };

  useEffect(() => {
    countWords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("description")]);

  const type = [
    { name: "Stories", key: "stories" },
    { name: "News", key: "news" },
    { name: "Resources", key: "resources" },
  ];

  const countWords = () => {
    // const contentState = editorState.getCurrentContent();
    // const contentState = watch("description");
    // const rawContent = convertToRaw(contentState);
    // const text = rawContent.blocks.map((block) => block.text).join(" ");
    // const words = text.trim().split(/\s+/).filter(Boolean);

    const totalSeconds = (watch("descriptionEn")?.length / 200) * 60;
    const minutes = Math.ceil(totalSeconds / 60);
    const formattedTime = `${minutes} minute${minutes !== 1 ? "s" : ""}`;

    return setValue("estimatedTimeToRead", formattedTime);
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append(`TitleAr`, data.titleAr);
    formData.append(`TitleEn`, data.titleEn);
    formData.append(`TitleKu`, data.titleKu);
    formData.append(`DescriptionAr`, data.descriptionAr);
    formData.append(`DescriptionEn`, data.descriptionEn);
    formData.append(`DescriptionKu`, data.descriptionKu);
    formData.append(`File`, data.temp_attachments);
    formData.append(`Type`, data.type);
    formData.append(`EstimatedTimeToRead`, data.estimatedTimeToRead);
    if (data.writtenBy) formData.append(`WrittenBy`, data.writtenBy);
    if (data.writtenByUrl) formData.append(`WrittenByUrl`, data.writtenByUrl);
    if (isAdd) {
      dispatch(Posts.create(formData, _PageNo, _SearchBody));
    } else {
      dispatch(
        Posts.update(
          data.id,
          {
            // type: data.type,
            titleAr: data.titleAr,
            titleEn: data.titleEn,
            titleKu: data.titleKu,
            descriptionAr: data.descriptionAr,
            descriptionEn: data.descriptionEn,
            descriptionKu: data.descriptionKu,
            attachmentId: data.attachmentId,
            estimatedTimeToRead: data.estimatedTimeToRead,
            writtenBy: data.writtenBy ?? null,
            writtenByUrl: data.writtenByUrl ?? null,
          },
          _PageNo,
          _SearchBody
        )
      );
    }
    // const hasTextTitle = editorStateTitle.getCurrentContent().hasText();
    // const hasText = editorState.getCurrentContent().hasText();
    // if (preview) {
    //   if (hasText & hasTextTitle) {
    //     if (isAdd) {
    //       data.attachmentUrl = files;
    //     }
    //     dispatch(setPreviewData(data));
    //     dispatch(setById(data));
    //     window.open("/preview_post", "_blank", "noreferrer");
    //   } else {
    //     dispatch(
    //       showNotification({
    //         message: "Title and Description are required",
    //         type: "error",
    //       })
    //     );
    //   }
    // } else {
    //   if (hasText & hasTextTitle) {
    //     const formData = new FormData();
    //     formData.append(`Title`, data.title);
    //     formData.append(`Description`, data.description);
    //     formData.append(`File`, data.temp_attachments);
    //     formData.append(`Type`, data.type);
    //     formData.append(`EstimatedTimeToRead`, data.estimatedTimeToRead);
    //     if (data.writtenBy) formData.append(`WrittenBy`, data.writtenBy);
    //     if (data.writtenByUrl)
    //       formData.append(`WrittenByUrl`, data.writtenByUrl);
    //     if (isAdd) {
    //       dispatch(Posts.create(formData, _PageNo, _SearchBody));
    //     } else {
    //       dispatch(
    //         Posts.update(
    //           data.id,
    //           {
    //             type: data.type,
    //             title: data.title,
    //             description: data.description,
    //             attachmentId: data.attachmentId,
    //             estimatedTimeToRead: data.estimatedTimeToRead,
    //             writtenBy: data.writtenBy ?? null,
    //             writtenByUrl: data.writtenByUrl ?? null,
    //           },
    //           _PageNo,
    //           _SearchBody
    //         )
    //       );
    //     }
    //   } else {
    //     dispatch(
    //       showNotification({
    //         message: "Title and Description are required",
    //         type: "error",
    //       })
    //     );
    //   }
    // }
  };

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setDialog());
  };

  function getId(embeddedLink) {
    if (embeddedLink.indexOf("youtube") >= 0) {
      embeddedLink = embeddedLink.replace("watch?v=", "embed/");
      embeddedLink = embeddedLink.replace("/watch/", "/embed/");
      embeddedLink = embeddedLink.replace("youtu.be/", "youtube.com/embed/");
    }
    return embeddedLink;
  }

  return (
    <Dialog
      open={_Post.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "Add " : "Update"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={6}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={type}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.key === value.key
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Select Type"
                        size="small"
                      />
                    )}
                    onChange={(e, newValue) => {
                      setValue("type", newValue ? newValue.key : null);
                    }}
                    value={
                      type.filter((type) => type.key === field.value)[0] ?? null
                    }
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Controller
                name="estimatedTimeToRead"
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled
                    required
                    size="small"
                    label="Estimated time to read"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="titleAr"
                control={control}
                rules={{
                  required: { value: true, message: "Required" },
                }}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Title Arabic"
                    fullWidth
                    {...field}
                    error={!!errors.titleAr}
                    helperText={errors.titleAr ? errors.titleAr.message : ""}
                  />
                  // <div className="editor-title">
                  //   <span style={{ color: "#666666", padding: 2 }}>
                  //     Title *
                  //   </span>
                  //   <Editor
                  //     handlePastedText={() => false}
                  //     toolbarStyle={{
                  //       position: "sticky",
                  //       top: 0,
                  //       zIndex: 99999,
                  //     }}
                  //     toolbar={{
                  //       options: [
                  //         "inline",
                  //         // "blockType",
                  //         "fontSize",
                  //         "fontFamily",
                  //         "textAlign",
                  //         "colorPicker",
                  //         "link",
                  //       ],
                  //       inline: { inDropdown: true },
                  //       list: { inDropdown: true },
                  //       textAlign: { inDropdown: true },
                  //       link: { inDropdown: true },
                  //       history: { inDropdown: true },
                  //     }}
                  //     editorState={editorStateTitle}
                  //     wrapperClassName="demo-wrapper"
                  //     editorClassName="demo-editor"
                  //     onEditorStateChange={onEditorStateChangeTitle}
                  //   />
                  // </div>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="titleEn"
                control={control}
                rules={{
                  required: { value: true, message: "Required" },
                }}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Title English"
                    fullWidth
                    {...field}
                    error={!!errors.titleEn}
                    helperText={errors.titleEn ? errors.titleEn.message : ""}
                  />
                  // <div className="editor-title">
                  //   <span style={{ color: "#666666", padding: 2 }}>
                  //     Title *
                  //   </span>
                  //   <Editor
                  //     handlePastedText={() => false}
                  //     toolbarStyle={{
                  //       position: "sticky",
                  //       top: 0,
                  //       zIndex: 99999,
                  //     }}
                  //     toolbar={{
                  //       options: [
                  //         "inline",
                  //         // "blockType",
                  //         "fontSize",
                  //         "fontFamily",
                  //         "textAlign",
                  //         "colorPicker",
                  //         "link",
                  //       ],
                  //       inline: { inDropdown: true },
                  //       list: { inDropdown: true },
                  //       textAlign: { inDropdown: true },
                  //       link: { inDropdown: true },
                  //       history: { inDropdown: true },
                  //     }}
                  //     editorState={editorStateTitle}
                  //     wrapperClassName="demo-wrapper"
                  //     editorClassName="demo-editor"
                  //     onEditorStateChange={onEditorStateChangeTitle}
                  //   />
                  // </div>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="titleKu"
                control={control}
                rules={{
                  required: { value: true, message: "Required" },
                }}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Title Kurdish"
                    fullWidth
                    {...field}
                    error={!!errors.titleKu}
                    helperText={errors.titleKu ? errors.titleKu.message : ""}
                  />
                  // <div className="editor-title">
                  //   <span style={{ color: "#666666", padding: 2 }}>
                  //     Title *
                  //   </span>
                  //   <Editor
                  //     handlePastedText={() => false}
                  //     toolbarStyle={{
                  //       position: "sticky",
                  //       top: 0,
                  //       zIndex: 99999,
                  //     }}
                  //     toolbar={{
                  //       options: [
                  //         "inline",
                  //         // "blockType",
                  //         "fontSize",
                  //         "fontFamily",
                  //         "textAlign",
                  //         "colorPicker",
                  //         "link",
                  //       ],
                  //       inline: { inDropdown: true },
                  //       list: { inDropdown: true },
                  //       textAlign: { inDropdown: true },
                  //       link: { inDropdown: true },
                  //       history: { inDropdown: true },
                  //     }}
                  //     editorState={editorStateTitle}
                  //     wrapperClassName="demo-wrapper"
                  //     editorClassName="demo-editor"
                  //     onEditorStateChange={onEditorStateChangeTitle}
                  //   />
                  // </div>
                )}
              />
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <Controller
                name="writtenBy"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="Written By"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="writtenByUrl"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="Written By URL"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12} md={12}>
              <Controller
                name="descriptionAr"
                control={control}
                rules={{
                  required: { value: true, message: "Required" },
                }}
                render={({ field }) => {
                  return (
                    <TextField
                      required
                      size="small"
                      label="Description Arabic"
                      fullWidth
                      {...field}
                      multiline
                      rows={5}
                      error={!!errors.descriptionAr}
                      helperText={
                        errors.descriptionAr ? errors.descriptionAr.message : ""
                      }
                    />
                    // <div className="editor-description">
                    //   <span style={{ color: "#666666", padding: 2 }}>
                    //     Description *
                    //   </span>
                    //   <Editor
                    //     handlePastedText={() => false}
                    //     toolbarStyle={{
                    //       position: "sticky",
                    //       top: 0,
                    //       zIndex: 1000,
                    //     }}
                    //     toolbar={{
                    //       options: [
                    //         "inline",
                    //         "blockType",
                    //         "fontSize",
                    //         "fontFamily",
                    //         "list",
                    //         "textAlign",
                    //         "colorPicker",
                    //         "link",
                    //         "embedded",
                    //         "image",
                    //         "remove",
                    //         "history",
                    //       ],
                    //       embedded: {
                    //         embedCallback: getId,
                    //         defaultSize: {
                    //           height: "500",
                    //           width: "800",
                    //         },
                    //       },
                    //       inline: { inDropdown: true },
                    //       list: { inDropdown: true },
                    //       textAlign: { inDropdown: true },
                    //       link: { inDropdown: true },
                    //       history: { inDropdown: true },
                    //     }}
                    //     editorState={editorState}
                    //     wrapperClassName="demo-wrapper"
                    //     editorClassName="demo-editor"
                    //     onEditorStateChange={onEditorStateChange}
                    //   />
                    // </div>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="descriptionEn"
                control={control}
                rules={{
                  required: { value: true, message: "Required" },
                }}
                render={({ field }) => {
                  return (
                    <TextField
                      required
                      size="small"
                      label="Description English"
                      fullWidth
                      {...field}
                      multiline
                      rows={5}
                      error={!!errors.descriptionEn}
                      helperText={
                        errors.descriptionEn ? errors.descriptionEn.message : ""
                      }
                    />
                    // <div className="editor-description">
                    //   <span style={{ color: "#666666", padding: 2 }}>
                    //     Description *
                    //   </span>
                    //   <Editor
                    //     handlePastedText={() => false}
                    //     toolbarStyle={{
                    //       position: "sticky",
                    //       top: 0,
                    //       zIndex: 1000,
                    //     }}
                    //     toolbar={{
                    //       options: [
                    //         "inline",
                    //         "blockType",
                    //         "fontSize",
                    //         "fontFamily",
                    //         "list",
                    //         "textAlign",
                    //         "colorPicker",
                    //         "link",
                    //         "embedded",
                    //         "image",
                    //         "remove",
                    //         "history",
                    //       ],
                    //       embedded: {
                    //         embedCallback: getId,
                    //         defaultSize: {
                    //           height: "500",
                    //           width: "800",
                    //         },
                    //       },
                    //       inline: { inDropdown: true },
                    //       list: { inDropdown: true },
                    //       textAlign: { inDropdown: true },
                    //       link: { inDropdown: true },
                    //       history: { inDropdown: true },
                    //     }}
                    //     editorState={editorState}
                    //     wrapperClassName="demo-wrapper"
                    //     editorClassName="demo-editor"
                    //     onEditorStateChange={onEditorStateChange}
                    //   />
                    // </div>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="descriptionKu"
                control={control}
                rules={{
                  required: { value: true, message: "Required" },
                }}
                render={({ field }) => {
                  return (
                    <TextField
                      required
                      size="small"
                      label="Description Kurdish"
                      fullWidth
                      {...field}
                      multiline
                      rows={5}
                      error={!!errors.descriptionKu}
                      helperText={
                        errors.descriptionKu ? errors.descriptionKu.message : ""
                      }
                    />
                    // <div className="editor-description">
                    //   <span style={{ color: "#666666", padding: 2 }}>
                    //     Description *
                    //   </span>
                    //   <Editor
                    //     handlePastedText={() => false}
                    //     toolbarStyle={{
                    //       position: "sticky",
                    //       top: 0,
                    //       zIndex: 1000,
                    //     }}
                    //     toolbar={{
                    //       options: [
                    //         "inline",
                    //         "blockType",
                    //         "fontSize",
                    //         "fontFamily",
                    //         "list",
                    //         "textAlign",
                    //         "colorPicker",
                    //         "link",
                    //         "embedded",
                    //         "image",
                    //         "remove",
                    //         "history",
                    //       ],
                    //       embedded: {
                    //         embedCallback: getId,
                    //         defaultSize: {
                    //           height: "500",
                    //           width: "800",
                    //         },
                    //       },
                    //       inline: { inDropdown: true },
                    //       list: { inDropdown: true },
                    //       textAlign: { inDropdown: true },
                    //       link: { inDropdown: true },
                    //       history: { inDropdown: true },
                    //     }}
                    //     editorState={editorState}
                    //     wrapperClassName="demo-wrapper"
                    //     editorClassName="demo-editor"
                    //     onEditorStateChange={onEditorStateChange}
                    //   />
                    // </div>
                  );
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: isAdd ? null : "flex", justifyContent: "center" }}
            >
              <Controller
                name="temp_attachments"
                control={control}
                rules={{
                  required: {
                    value: isAdd ? true : false,
                    message: "Required",
                  },
                }}
                render={({ field }) =>
                  isAdd ? (
                    <Box>
                      <FilePondInput
                        required
                        field={field}
                        onChange={(fileItems) => {
                          setValue(`temp_attachments`, fileItems[0]?.file);
                          setFiles(
                            fileItems[0]?.file
                              ? URL.createObjectURL(fileItems[0]?.file)
                              : null
                          );
                        }}
                      />
                      <Typography color="red">
                        {errors.temp_attachments
                          ? "Attachment Field Is " +
                            errors.temp_attachments.message
                          : ""}
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      component="img"
                      src={_Post.form.attachmentUrl}
                      sx={{
                        width: 300,
                        border: "solid 1px gray",
                        padding: 4,
                        borderRadius: 5,
                      }}
                      alt="post image"
                    />
                  )
                }
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Fab
                  onClick={() => setPreview(true)}
                  type="submit"
                  color="warning"
                  size="small"
                  aria-label="add"
                  sx={{
                    position: "absolute",
                    top: 22,
                    right: 65,
                    zIndex: 99999,
                  }}
                >
                  <AiFillEye size={25} />
                </Fab>
                <Fab
                  onClick={() => setPreview(false)}
                  type="submit"
                  color={!loading ? "primary" : "default"}
                  size="small"
                  aria-label="add"
                  sx={{
                    position: "absolute",
                    top: 22,
                    right: 15,
                    zIndex: 99999,
                  }}
                >
                  {loading ? (
                    <CircularProgress sx={{ padding: 0.25 }} />
                  ) : (
                    <AiOutlineSave size={25} />
                  )}
                </Fab>
              </Stack>
            </Grid> */}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ width: 200, m: 2 }}
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color={"primary"}
        >
          {loading ? <CircularProgress color="error" size={20} /> : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PostForm;
