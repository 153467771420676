import axios from "axios";
const authInterceptor = (config) => {
  const token = localStorage.getItem("token");
  if (config.url === "/api/Auth/Login") {
    config.headers["Content-Type"] = "application/json";
    config.headers["accept"] = "text/plain";
    // config.headers["App-Version"] = "web";
    // config.headers["Accept-Language"] = "en";
  } else if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["accept"] = "text/plain";
    config.headers["Content-Type"] = "application/json";
    config.headers["App-Version"] = "web";
    config.headers["Accept-Language"] = "en";
  }
  return config;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

api.interceptors.request.use(authInterceptor);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // eslint-disable-next-line default-case
    switch (error.response.status) {
      case 401:
        localStorage.removeItem("token");
        window.location.href = "/login";
        break;
      // case 0:
      //   localStorage.removeItem("token")
      //   window.location.href = "/login";
      //   break;
    }

    return Promise.reject(error);
  }
);
export default api;
