import React, { forwardRef } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Stack,
  Divider,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { setDialog } from "../../../app/slices/formSlice";
import dayjs from "dayjs";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PostForm = () => {
  const _Form = useSelector((state) => state.form.form);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_Form.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"Form Details"}
        </Typography>
        <Divider />
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>First Name:</Typography>
              <Stack direction="row">
                <Typography variant="h4">{_Form.form.firstName}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>Last Name:</Typography>
              <Stack direction="row">
                <Typography variant="h4">{_Form.form.lastName}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>Mobile:</Typography>
              <Stack direction="row">
                <Typography variant="h4">{_Form.form.mobile}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>Email:</Typography>
              <Stack direction="row">
                <Typography variant="h4">{_Form.form.email}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>Date:</Typography>
              <Stack direction="row">
                <Typography variant="h4">
                  {dayjs(_Form.form.createdAt).format("YYYY-MM-DD / hh:mm A")}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>University:</Typography>
              <Stack direction="row">
                <Typography variant="h4">
                  {_Form.form.universityName}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>College:</Typography>
              <Stack direction="row">
                <Typography variant="h4">{_Form.form.collegeName}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>Email:</Typography>
              <Stack direction="row">
                <Typography variant="h4">{_Form.form.email}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>Section Name:</Typography>
              <Stack direction="row">
                <Typography variant="h4">{_Form.form.sectionName}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>Graduation Year:</Typography>
              <Stack direction="row">
                <Typography variant="h4">
                  {_Form.form.graduationYear}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography sx={{ fontSize: 18 }}>Needed Course:</Typography>
              <Stack direction="row">
                <Typography variant="h4">{_Form.form.neededCourse}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5} alignItems="start">
              <Typography sx={{ fontSize: 18 }}>preferredTime:</Typography>
              <Stack direction="row">
                <Typography sx={{ fontSize: 18 }}>
                  {_Form.form.preferredTime}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack direction="row" spacing={0.5} alignItems="start">
              <Typography sx={{ fontSize: 18 }}>Note:</Typography>
              <Stack direction="row">
                <Typography sx={{ fontSize: 18 }}>{_Form.form.note}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PostForm;
