import { forwardRef, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  Box,
  DialogActions,
  Button,
  Stack,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setChangeBackgroundDialog,
  Attachments,
} from "../../../app/slices/attachmentSlice";
import { Controller, useForm } from "react-hook-form";
import FilePondInput from "../../../helpers/filepond";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChangeBackgroundImageForm = () => {
  const { form, loading, dialog, image } = useSelector(
    (state) => state.attachment.changeBackgroundImage
  );

  useEffect(() => console.log(form.imageUrl), [form]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: form,
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append(`File`, data.temp_attachments);
    dispatch(Attachments.changeBackgroundImage(formData));
  };

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setChangeBackgroundDialog(image));
  };

  return (
    <Dialog
      open={dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"Update Background Image"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                display: form.imageUrl ? null : "flex",
                justifyContent: "center",
              }}
            >
              <Controller
                name="temp_attachments"
                control={control}
                rules={{
                  required: {
                    value: form.imageUrl ? true : false,
                    message: "Required",
                  },
                }}
                render={({ field }) => (
                  <Stack spacing={2}>
                    <Box>
                      <FilePondInput
                        required
                        field={field}
                        onChange={(fileItems) => {
                          setValue(`temp_attachments`, fileItems[0]?.file);
                        }}
                      />
                      <Typography color="red">
                        {errors.temp_attachments
                          ? "Attachment Field Is " +
                            errors.temp_attachments.message
                          : ""}
                      </Typography>
                    </Box>
                    <Stack direction="row" spacing={2} justifyContent="center">
                      <Box
                        component="img"
                        src={form.imageUrl}
                        sx={{
                          width: 300,
                          border: "solid 1px gray",
                          padding: 4,
                          borderRadius: 5,
                        }}
                        alt="post image"
                      />
                    </Stack>
                  </Stack>
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ width: 200, m: 2 }}
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color={"primary"}
        >
          {loading ? <CircularProgress color="error" size={20} /> : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeBackgroundImageForm;
