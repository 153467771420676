import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
import { CourseActions } from "./courseSlice";
const courseCategoryApi = factory.get("courseCategory");
const initialState = {
  searchBody: {},
  dialog: false,
  courseCategory: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      nameAr: "",
      nameEn: "",
      nameKu: "",
      attachment: "",
      attachmentId: null,
    },
  },
  courseCategories: {
    loading: false,
    data: [],
    total: 0,
  },
  courseCategoryList: {
    loading: false,
    data: [],
    total: 0,
  },
};

export const courseCategorySlice = createSlice({
  name: "courseCategory",
  initialState,
  reducers: {
    setOpenDialog: (state, action) => {
      state.dialog = !state.dialog;
    },
    setTypesDialog: (state, action) => {
      state.courseCategory.dialog = !state.courseCategory.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.courseCategories.data = payload.data.data;
      state.courseCategories.total = payload.data.total;
      state.courseCategories.total_amount = payload.data.total_amount;
    },
    setDataList: (state, { payload }) => {
      state.courseCategoryList.data = payload.data.data;
    },
    setById: (state, { payload }) => {
      state.courseCategory.form.id = payload.id;
      state.courseCategory.form.nameAr = payload.nameAr;
      state.courseCategory.form.nameEn = payload.nameEn;
      state.courseCategory.form.nameKu = payload.nameKu;
      state.courseCategory.form.attachment = payload.attachment.url;
      state.courseCategory.form.attachmentId = payload.attachment.id;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.courseCategory.form = initialState.courseCategory.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setTypesDialog,
  setOpenDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDataList,
} = courseCategorySlice.actions;

export default courseCategorySlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("courseCategories"));
    const res = await courseCategoryApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("courseCategories"));
  } catch (err) {
    dispatch(setLoading("courseCategories"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("courseCategories"));
    const res = await courseCategoryApi.getAllList(params);
    dispatch(setDataList(res));
    dispatch(setLoading("courseCategories"));
  } catch (err) {
    dispatch(setLoading("courseCategories"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("courseCategory"));
    await courseCategoryApi.delete(id);
    dispatch(
      showNotification({
        message: "Deleted successfully",
        type: "success",
      })
    );
    dispatch(setLoading("courseCategory"));
    dispatch(getAll());
    dispatch(CourseActions.getAllCourses());
  } catch (err) {
    dispatch(setLoading("courseCategory"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("courseCategories"));
    const res = await courseCategoryApi.getById(id);
    dispatch(setById(res.data.data));

    dispatch(setTypesDialog());
    dispatch(setLoading("courseCategories"));
  } catch (err) {
    dispatch(setLoading("courseCategories"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("courseCategory"));
    await courseCategoryApi.create(data);
    dispatch(
      showNotification({
        message: "Added successfully",
        type: "success",
      })
    );
    dispatch(setLoading("courseCategory"));
    dispatch(setTypesDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(setLoading("courseCategory"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data) => async (dispatch) => {
  dispatch(setLoading("courseCategory"));
  try {
    await courseCategoryApi.update(id, data);
    dispatch(
      showNotification({
        message: "Updated successfully",
        type: "success",
      })
    );
    dispatch(setLoading("courseCategory"));
    dispatch(setTypesDialog());
    dispatch(getAll());
    dispatch(CourseActions.getAllCourses());
  } catch (err) {
    dispatch(setLoading("courseCategory"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const CourseCategoryActions = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getAllList,
};
