import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  Typography,
  TextField,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { AiFillEye } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Forms,
  setPageNo,
  setSearchBody,
} from "../../../../app/slices/formSlice";
import PostForm from "../../form";
import dayjs from "dayjs";
import DateRangepicker from "../../../../helpers/dateRangePicker";
import { MdOutlineClear } from "react-icons/md";

const FormTable = () => {
  const dispatch = useDispatch();

  const _FormDialog = useSelector((state) => state.form.form.dialog);
  const _Forms = useSelector((state) => state.form.forms);
  const loading = useSelector((state) => state.form.form.loading);
  const _SearchBody = useSelector((state) => state.form.searchBody);
  const _PageNo = useSelector((state) => state.user.pageNo);

  const [page, setPage] = useState(15);

  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.2,
      renderCell: (params) => (
        <Tooltip title={params.row.email}>
          <Typography>{params.row.email}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "universityName",
      headerName: "University",
      flex: 1,
    },
    {
      field: "collegeName",
      headerName: "College",
      flex: 1,
    },
    {
      field: "sectionName",
      headerName: "Section",
      flex: 1,
    },
    {
      field: "graduationYear",
      headerName: "Graduation Year",
      flex: 1,
    },
    {
      field: "neededCourse",
      headerName: "Needed Course",
      flex: 1,
    },
    {
      field: "preferredTime",
      headerName: "Preferred Time",
      flex: 1,
    },
    {
      field: "note",
      headerName: "Note",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.note}>
          <Typography noWrap={true}>{params.row.note}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography>
            {dayjs(params.row.createdAt).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="subtitle2">
            {dayjs(params.row.createdAt).format("hh:mm A")}
          </Typography>
        </Box>
      ),
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="primary"
              onClick={() => dispatch(Forms.getById(params.row.id))}
            >
              <AiFillEye />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Forms.getAll(_PageNo, _SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_SearchBody]);

  return (
    <Card>
      <CardContent>
        <Box>{_FormDialog && <PostForm />}</Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              size="small"
              value={_SearchBody.neededCourse}
              onChange={(e) => {
                dispatch(
                  setSearchBody({
                    ..._SearchBody,
                    neededCourse: e.target.value,
                  })
                );
              }}
              label="Search Course Name"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DateRangepicker
              startDate={_SearchBody.fromCreatedAt}
              endDate={_SearchBody.toCreatedAt}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  dispatch(
                    setSearchBody({
                      ..._SearchBody,
                      fromCreatedAt: dayjs(searchDate[0].startDate).format(
                        "YYYY-MM-DD"
                      ),
                      toCreatedAt: dayjs(searchDate[0].endDate).format(
                        "YYYY-MM-DD"
                      ),
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
              <Tooltip title="Clear Filter">
                <IconButton
                  onClick={() => {
                    dispatch(
                      setSearchBody({
                        neededCourse: "",
                        fromCreatedAt: null,
                        toCreatedAt: null,
                        pageSize: 15,
                      })
                    );
                    dispatch(setPageNo(15));
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={_Forms.data}
                columns={columns}
                pageSize={page}
                rowCount={_Forms.total}
                loading={loading}
                rowHeight={50}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => {
                  dispatch(setPageNo(newPage));
                }}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPage(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FormTable;
