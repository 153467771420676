import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiTrash, BiEdit } from "react-icons/bi";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import { MdOutlineClear } from "react-icons/md";
import {
  CourseActions,
  setSearchBody,
} from "../../../../app/slices/courseSlice";
import { SelectCourseCategory } from "../../../../components/Selectors/SelectCourseCategory";

const CourseTable = () => {
  const dispatch = useDispatch();

  const _Course = useSelector((state) => state.course.courses);
  const loading = useSelector((state) => state.course.courses.loading);
  const _SearchBody = useSelector((state) => state.course.searchBody);
  const _CourseCategory = useSelector(
    (state) => state.courseCategory.courseCategoryList.data
  );

  const [search, setSearch] = useState(_SearchBody);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(CourseActions.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "nameEn",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "category",
      headerName: "Department",
      flex: 1,
      renderCell: (params) => params.row.category?.nameEn,
    },
    {
      field: "descriptionEn",
      headerName: "Description",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          followCursor
          title={
            <Typography variant="h5">{params.row.descriptionEn}</Typography>
          }
        >
          <Box
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal",
            }}
          >
            {params.value}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "attachment",
      headerName: "Attachment",
      flex: 1,
      renderCell: (params) => (
        <a
          href={params.row.attachment?.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box
            component="img"
            src={params.row.attachment?.url}
            width="100px"
            height="100px"
            alt="attachment"
            sx={{ borderRadius: "10px", objectFit: "contain" }}
          />
        </a>
      ),
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => dispatch(CourseActions.getById(params.row.id))}
            >
              <BiEdit />
            </IconButton>
            <IconButton onClick={() => handleDeleteClick(params.row)}>
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(CourseActions.getAllCourses(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        name: search.name,
        courseCategoryId: search.courseCategoryId ?? "",
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, search]);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="Confirmation"
            message="Are you sure you want to delete"
            name={dialogData.name}
            confirmText="Delete"
            cancelText="Cancel"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={4}>
            <TextField
              size="small"
              value={search.name}
              onChange={(e) => {
                setSearch({
                  ...search,
                  name: e.target.value,
                });
              }}
              label="Search Course Name"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <SelectCourseCategory
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  courseCategoryId: newValue ? newValue.id : null,
                });
              }}
              value={
                _CourseCategory.filter(
                  (x) => x.id === search.courseCategoryId
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Clear Filter">
              <IconButton
                onClick={() => {
                  setSearch({
                    courseCategoryId: null,
                    name: "",
                  });
                  setPageSize(15);
                  setCurrentPage(0);
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={
                  _Course.data?.map((x, index) => ({
                    ...x,
                    index: index + 1,
                  })) ?? []
                }
                columns={columns}
                pageSize={pageSize}
                rowCount={_Course.total}
                loading={loading}
                rowHeight={120}
                paginationMode="client"
                selectionModel={[]}
                rowsPerPageOptions={[5, 15, 25, 50, 100]}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CourseTable;
