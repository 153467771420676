import user from "./endpoints/user";
import auth from "./endpoints/auth";
import form from "./endpoints/form";
import post from "./endpoints/posts";
import attachment from "./endpoints/attachment";
import course from "./endpoints/course";
import courseCategory from "./endpoints/courseCategory";

const repositories = {
  user,
  auth,
  form,
  post,
  attachment,
  course,
  courseCategory,
};

export const factory = {
  get: (name) => repositories[name],
};
