import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import appSlice from "./slices/appSlice";
import notificationSlice from "./slices/notificationSlice";
import userSlice from "./slices/userSlice";
import authSlice from "./slices/authSlice";
import formSlice from "./slices/formSlice";
import postsSlice from "./slices/postsSlice";
import courseCategorySlice from "./slices/courseCategorySlice";
import courseSlice from "./slices/courseSlice";
import attachmentSlice from "./slices/attachmentSlice";

export const store = configureStore({
  reducer: {
    app: appSlice,
    notification: notificationSlice,
    user: userSlice,
    auth: authSlice,
    form: formSlice,
    post: postsSlice,
    courseCategory: courseCategorySlice,
    course: courseSlice,
    attachment: attachmentSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

setupListeners(store.dispatch);
