import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/Attachment/${pageNo}?${params}`);
  },
  create(data) {
    return api.post(`/api/Attachment`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  getById(id) {
    return api.get(`/api/Attachment/byId/${id}`);
  },
  update(id, data) {
    return api.put(`/api/Attachment/${id}`, data);
  },
  delete(id) {
    return api.delete(`/api/Attachment${id}`);
  },
  changeBackgroundImage(data) {
    return api.post(`/api/Attachment/Background`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  getBackgroundImage() {
    return api.get(`/api/Attachment/Background`);
  },
};
