import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const postApi = factory.get("post");
const initialState = {
  pageNo: 1,
  searchBody: {},
  post: {
    loading: false,
    dialog: false,
    form: {
      id: null,
      type: null,
      titleAr: "",
      titleEn: "",
      titleKu: "",
      descriptionEn: "",
      descriptionAr: "",
      descriptionKu: "",
      attachmentUrl: "",
      estimatedTimeToRead: "",
      writtenBy: "",
      writtenByUrl: "",
      temp_attachments: "",
    },
  },
  posts: {
    loading: false,
    data: [],
    total: 0,
  },
  previewData: {},
};

export const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    setDialog: (state, { payload }) => {
      state.post.dialog = !state.post.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.posts.data = payload.data.data.values;
      state.posts.total = payload.data.data.totalCount;
    },
    setPreviewData: (state, { payload }) => {
      localStorage.setItem("postPreview", JSON.stringify(payload));
      state.previewData = payload;
    },
    setById: (state, { payload }) => {
      state.post.form.id = payload.id;
      state.post.form.type = payload.type;
      state.post.form.titleAr = payload.titleAr;
      state.post.form.titleEn = payload.titleEn;
      state.post.form.titleKu = payload.titleKu;
      state.post.form.descriptionAr = payload.descriptionAr;
      state.post.form.descriptionEn = payload.descriptionEn;
      state.post.form.descriptionKu = payload.descriptionKu;
      state.post.form.attachmentUrl = payload.attachmentUrl;
      state.post.form.attachmentId = payload.attachmentId;
      state.post.form.writtenByUrl = payload.writtenByUrl ?? "";
      state.post.form.writtenBy = payload.writtenBy ?? "";
      state.post.form.estimatedTimeToRead = payload.estimatedTimeToRead ?? "";
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.post.form = initialState.post.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    setPageNo: (state, { payload }) => {
      state.pageNo = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setPageNo,
  setPreviewData,
} = postSlice.actions;

export default postSlice.reducer;

//axios
const getAll = (pageNo, searchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("post"));
    const res = await postApi.getAll(pageNo, searchBody);
    dispatch(setDataTable(res));
    dispatch(setLoading("post"));
  } catch (err) {
    dispatch(setLoading("post"));
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, pageNo, _SearchBody) => async (dispatch) => {
  try {
    await postApi.delete(id);
    dispatch(
      showNotification({
        message: "Post Deleted Successfully",
        type: "success",
      })
    );
    dispatch(getAll(pageNo, _SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("post"));
    const res = await postApi.getById(id);
    dispatch(setById(res.data.data));
    dispatch(setDialog());
    dispatch(setLoading("post"));
  } catch (err) {
    dispatch(setLoading("post"));
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, pageNo, searchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("post"));
    await postApi.create(data);
    dispatch(
      showNotification({
        message: "Post Created Successfully",
        type: "success",
      })
    );
    dispatch(setLoading("post"));
    dispatch(setDialog());
    dispatch(getAll(pageNo, searchBody));
  } catch (err) {
    dispatch(setLoading("post"));
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("post"));
    await postApi.update(id, data);
    dispatch(
      showNotification({
        message: "Post Updated Successfully",
        type: "success",
      })
    );
    dispatch(setLoading("post"));
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(setLoading("post"));
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Posts = {
  getAll,
  deleteById,
  getById,
  create,
  update,
};
