import { Grid } from "@mui/material";
import React from "react";
import ChangeBackgroundImageTable from "./home/table";
import ChangeBackgroundImageHeader from "./home/header";

const ChangeBackgroundImage = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ChangeBackgroundImageHeader />
      </Grid>
      <Grid item xs={12}>
        <ChangeBackgroundImageTable />
      </Grid>
    </Grid>
  );
};

export default ChangeBackgroundImage;
